import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import WaveBg from 'images/nte-bg-wave.inline.svg';
import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Numbers from './Numbers';
import Graphs from './Graphs';
import useWindow from 'context/useWindow';

const ComponentWrapper = styled.div`
	padding: 0;
`;

const Wave = styled(WaveBg)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100vw;
	z-index: -1;
	min-height: 100vh;
	${p =>
		p.theme.media.xLarge(css`
			top: 100px;
			min-height: 1670px;
		`)}
	${p =>
		p.theme.media.large(css`
			top: 75px;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			min-height: 75vh;
		`)}
`;

/**
 * Renders statistics with animations and gradient colors.
 * @param {Object} props - Component props.
 * @param {string} props.title - Title for the statistics section.
 * @param {string} props.introText - Introduction text.
 * @param {Array} props.stat - Array of objects representing statistics.
 * @param {string} props.type - The type of component ("Tall", "Sirkler med tall" or "Graf").
 * @param {boolean} props.transitions - Whether to apply animations.
 * @returns {JSX.Element} Rendered component.
 */
export default function Stats(props) {
	const statsRef = useRef(null);
	const [startCounting, setStartingCounting] = useState(false);

	const { windowWidth } = useWindow();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setStartingCounting(true);
					observer.disconnect(); // Disconnect the observer once the element is in viewport
				}
			},
			{
				root: null, // Use the viewport as the root
				rootMargin: '0px', // No margin
				threshold: windowWidth <= 768 ? 0.5 : 1.0, // Fully in viewport
			}
		);

		if (statsRef.current) {
			observer.observe(statsRef.current);

			return () => {
				observer.disconnect(); // Disconnect the observer when unmounting or ref changes
			};
		}
	}, [statsRef, windowWidth]);

	// Determine if the component is a graph
	const isGraph =
		['Søylediagram', 'Linjediagram'].includes(props.type) &&
		props.jsonStats?.length > 0;

	return (
		<>
			<ComponentWrapper
				ref={statsRef}
				className={`component__stats component__stats--${props.type}`}
				data-cy="component__stats">
				<MaxWidth>
					<Spacing {...props}>
						{!props?.settings?.includes(
							'Skjul tittel og intro'
						) && (
							<TitleAndText
								{...props}
								text={props.introText}
								headinglevel="h2"
								nested={true}
							/>
						)}

						{(isGraph && (
							<Graphs start={startCounting} {...props} />
						)) || <Numbers start={startCounting} {...props} />}
					</Spacing>
				</MaxWidth>
			</ComponentWrapper>

			{props?.settings?.includes('Vis bakgrunnsdekor') && <Wave />}
		</>
	);
}

/**
 * Renders a count-up animation from 0 to a specified value.
 * @param {Object} props - Component props.
 * @param {boolean} props.start - Wether to start the animation or not.
 * @param {number} props.endValue - The target value to count up to.
 * @param {number} props.duration - Duration of the animation in milliseconds.
 * @returns {JSX.Element} Rendered component.
 */
export function CountUpAnimation({ start, endValue, duration }) {
	const [count, setCount] = useState(0);
	const framesPerSecond = 60; // Adjust as needed

	useEffect(() => {
		if (!start) return;
		const frames = Math.ceil((duration / 1000) * framesPerSecond);
		const numericEndValue = Number(endValue); // Ensure endValue is treated as a number
		const step = Math.ceil(numericEndValue / frames);

		const interval = setInterval(() => {
			if (count + step >= numericEndValue) {
				setCount(numericEndValue);
				clearInterval(interval);
			} else {
				setCount(count + step);
			}
		}, 1000 / framesPerSecond);

		return () => {
			clearInterval(interval);
		};
	}, [start, count, endValue, duration]);

	// Ensure count and endValue are treated as numbers before formatting
	const formattedCount =
		count > 10000 ? Number(count).toLocaleString('de-DE') : count;
	const formattedEndValue =
		Number(endValue) > 10000
			? Number(endValue).toLocaleString('de-DE')
			: endValue;

	// Use formattedEndValue when count reaches endValue to ensure consistency
	const displayValue =
		count === Number(endValue) ? formattedEndValue : formattedCount;

	return <span>{displayValue}</span>;
}

/**
 * Generates a gradient of colors between the start and end colors.
 * @param {string} color - The color variant to generate a gradient for (Blå / Grønn).
 * @param {number} steps - Number of steps in the gradient.
 * @returns {string[]} Array of hex colors in the gradient.
 */
export function generateColorGradient(color = 'Blå', steps) {
	// NTE Grønn 500 or NTE Blå 500
	const startColor = color === 'Grønn' ? '#7DC189' : '#17A4D8';
	// NTE Grønn 800 or NTE Blå 800
	const endColor = color === 'Grønn' ? '#295334' : '#094462';

	const parseHex = hexColor =>
		hexColor.match(/[A-Za-z0-9]{2}/g).map(color => parseInt(color, 16));

	const interpolateColor = (color1, color2, factor) =>
		Math.round(color1 + factor * (color2 - color1));

	const startRGB = parseHex(startColor);
	const endRGB = parseHex(endColor);

	const colorGradient = [];

	for (let step = 0; step <= steps; step++) {
		const factor = step / steps;
		const interpolatedColor = startRGB.map((color, index) =>
			interpolateColor(color, endRGB[index], factor)
		);

		const hexColor = interpolatedColor
			.map(color => color.toString(16).padStart(2, '0'))
			.join('');

		colorGradient.push(`#${hexColor}`);
	}

	return colorGradient;
}
