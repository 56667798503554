import React from 'react';
import styled, { css } from 'styled-components';

import { AnimationWrap } from 'libs/animations';
import Chart from 'components/chart/Chart';

const Wrapper = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			> div {
				flex: 1;
				&:first-of-type:not(:only-child) {
					margin-right: 20px;
				}
				&:last-of-type:not(:only-child) {
					margin-left: 20px;
				}
			}
		`)}
	> div:not(:last-of-type) {
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: 20px;
			`)}
	}
`;

/**
 * Renders a graph with statistics.
 * @param {Object} props - Component props.
 * @param {string} props.type - The type of graph ("Linjediagram" or "Søylediagram").
 * @param {boolean} props.start - Whether to start the animation.
 * @param {Array} props.jsonStats - Array of objects representing statistics.
 * @param {boolean} props.transitions - Whether to apply animations.
 * @returns {JSX.Element} Rendered component.
 */
export default function Graphs({ start, ...props }) {
	if (!props?.jsonStats?.length > 0) return null;

	return (
		<AnimationWrap doanimate={props?.transitions} direction="up">
			<Wrapper>
				{props?.jsonStats?.map((graph, i) => (
					<Chart
						key={i}
						variant={
							props?.type === 'Linjediagram'
								? 'line-chart'
								: 'bar-chart'
						}
						start={start}
						color={props?.color}
						settings={props?.settings}
						{...graph}
					/>
				))}
			</Wrapper>
		</AnimationWrap>
	);
}
