import React from 'react';
import styled, { css } from 'styled-components';

import { AnimationWrap } from 'libs/animations';
import { ContentToText } from 'libs/content';
import { CountUpAnimation, generateColorGradient } from './Stats';
import Box from 'components/forms/Box';

const StatsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`;

const Stat = styled.div`
	flex: 1;
	padding: 0 20px;
	${p =>
		p.theme.media.smallOnly(css`
			flex: 1 0 calc(50% - 20px);
			padding: 0;
			&:nth-child(odd) {
				padding-right: 10px;
			}
			&:nth-child(even) {
				padding-left: 10px;
			}
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			flex: 1 0 calc(100% - 20px);
			padding: 0;
			&:nth-child(odd) {
				padding-right: 0;
			}
			&:nth-child(even) {
				padding-left: 0;
			}
		`)}

	p {
		margin: 0;
		span {
			font-size: 40px;
			line-height: 52px;
			font-weight: 500;
			display: block;
			${p =>
				p.theme.media.smallOnly(css`
					font-size: 36px;
					line-height: 46px;
				`)}
		}
		em {
			font-size: 17px;
			font-weight: 400;
			line-height: 29px;
			font-style: normal;
			margin: 0;
			display: block;
			${p =>
				p.theme.media.smallOnly(css`
					line-height: 24px;
				`)}
		}
	}
`;

/**
 * Renders statistics with counting-animations and gradient colors.
 * @param {Object} props - Component props.
 * @param {boolean} props.start - Wether to start the animation or not.
 * @param {string} props.color - Color for the gradient.
 * @param {Array} props.stat - Array of objects representing statistics.
 * @param {boolean} props.transitions - Whether to apply animations.
 * @returns {JSX.Element} Rendered component.
 */
export default function Numbers({ start, ...props }) {
	// Generate gradient colors based on the number of stats
	const colors = generateColorGradient(props?.color, props?.stat?.length);

	if (!props?.stat?.length > 0) return null;

	return (
		<AnimationWrap doanimate={props?.transitions} direction="up">
			<Box>
				<StatsWrapper>
					{props?.stat?.map((stat, i) => (
						<Stat key={i}>
							<AnimationWrap
								doanimate={props?.transitions}
								direction="up"
								delay={i / 2}>
								<p>
									<span
										style={{
											color: colors[i],
										}}>
										{isNaN(stat?.value) ? (
											stat?.value
										) : (
											<CountUpAnimation
												start={start}
												endValue={stat?.value}
												duration={2000 + i * 200}
											/>
										)}
									</span>

									<em>
										{stat?.label?.raw ? (
											<ContentToText
												data={stat?.label?.raw}
											/>
										) : (
											stat?.label
										)}
									</em>
								</p>
							</AnimationWrap>
						</Stat>
					))}
				</StatsWrapper>
			</Box>
		</AnimationWrap>
	);
}
